import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
// *******************toster****************************
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

import { formatDate, timeShow } from "../../../utils/common";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";

library.add(fas);

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    getDownloadDataExcel,
    Per_Page_Dropdown,
    usertype,
    Select2Data,
  } = useContext(Context);
  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [daysAgo, setDaysAgo] = useState("");

  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();
  const [searchDate, setSearchDate] = useState("");
  const [products, setProducts] = useState({});
  const [orderStatus, setOrderStatus] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchOrderStatus, setSearchOrderSatatus] = useState("");
  const [searchPaymentStatus, setSearchPaymentSatatus] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/order?page=${currentPage}&per_page=${perPage}&term=${search}&customerName=${customerName}&searchOrderStatus=${
        searchOrderStatus?.value || ""
      }&searchProduct=${searchProduct?.value || ""}&searchPaymentStatus=${
        searchPaymentStatus?.value || ""
      }&date=${searchDate ? formatDate(searchDate) : ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    customerName,
    searchProduct,
    searchOrderStatus,
    searchPaymentStatus,
    searchDate,
  ]);

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-order-status");

    if (response?.success) {
      setOrderStatus(await Select2Data(response?.data, "order_status_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
    GetAllOrdderStatus();
  }, []);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: false,
    col14: true,
    col15: true,
    col16: true,
    col17: true,
    col19: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/order/download",
          selectAllChecked,
          "Order List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Order Report"} link={"/home/ad-banner"} />
        <section className="AdvanceDashboard">
          <div className="row ">
            <div className="col-lg-12">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio mt-3">
                  <div className="">
                    <div className="row">
                      <div className="d-flex">
                        <div className="add me-1">
                          <div className="dropdown">
                            <button
                              className="btn btn-columns dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={colunms}
                                className="columns me-2 "
                                alt=""
                              />
                              Column Selection{" "}
                              <img src={top} className="top ms-1" alt="" />{" "}
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col1")
                                  }
                                  href="#"
                                >
                                  Sr. No.
                                  {visible?.col1 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col2")
                                  }
                                  href="#"
                                >
                                  Invoice No.
                                  {visible?.col2 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col3")
                                  }
                                  href="#"
                                >
                                  Customer Name
                                  {visible?.col3 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col19")
                                  }
                                  href="#"
                                >
                                  Customer Mobile Number
                                  {visible?.col19 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col4")
                                  }
                                  href="#"
                                >
                                  Order Date
                                  {visible?.col4 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col5")
                                  }
                                  href="#"
                                >
                                  Delivery Date
                                  {visible?.col5 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col6")
                                  }
                                  href="#"
                                >
                                  Product Name
                                  {visible?.col6 ? (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-2"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col7")
                                  }
                                  href="#"
                                >
                                  Amount
                                  {visible?.col7 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col8")
                                  }
                                  href="#"
                                >
                                  Order Status
                                  {visible?.col8 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col9")
                                  }
                                  href="#"
                                >
                                  Payment Mode
                                  {visible?.col9 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col10")
                                  }
                                  href="#"
                                >
                                  Payment Status
                                  {visible?.col10 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col11")
                                  }
                                  href="#"
                                >
                                  Delivery Pincode
                                  {visible?.col11 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-lg-4 col-md-4 col-12">
                          <div className="d-flex">
                            <div className="show me-2">
                              <p className="show mt-1">Show</p>
                            </div>
                            <div className="number me-2">
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={(e) => setperPage(e.target.value)}
                              >
                                {option?.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="entries">
                              <p className="show mt-1">entries</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="row">
                            <div className="col-lg-2 col-md-6 col-12">
                              <div className="sowing me-2">
                                <p className="show mt-3">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>

                            <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
                              <div className="num ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Customer Name"
                                  id=""
                                  value={customerName}
                                  onChange={(e) => {
                                    setCustomerName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
                              <div className="num ">
                                <Select
                                  options={products}
                                  placeholder="Select Product"
                                  value={searchProduct}
                                  onChange={(e) => {
                                    setSearchProduct(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3  col-lg-4  col-md-6 col-sm-12 col-12 mt-xl-0 mt-3">
                              <div className="num ">
                                <Select
                                  options={orderStatus}
                                  placeholder="Sort By Order Status"
                                  value={searchOrderStatus}
                                  onChange={(e) => {
                                    setSearchOrderSatatus(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2  col-lg-4  col-md-6 col-sm-10 col-12 mt-xl-0 mt-md-3">
                              <div className="num ">
                                <Select
                                  options={[
                                    { label: "Paid", value: "1" },
                                    { label: "Unpaid", value: "0" },
                                  ]}
                                  // value={searchPaymentStatus}
                                  placeholder="Payment Status"
                                  value={searchPaymentStatus}
                                  onChange={(e) => {
                                    setSearchPaymentSatatus(e);
                                  }}
                                />
                              </div>
                            </div>

                            <Col xl={7} lg={6} md={8} sm={6}>
                              <Form.Label>Download data in Excel</Form.Label>
                              <br />
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-lines"
                                className="pdf-icon"
                                variant="success"
                                // className="btn btn-view"
                                onClick={HandleDownload}
                              />
                            </Col>

                            <div className="col-xxl-2 col-xl-2  col-lg-4  col-md-3 col-sm-2 col-12 mt-md-3">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearchPaymentSatatus("");
                                    setSearchOrderSatatus("");
                                    setSearchProduct("");
                                    setCustomerName("");
                                    setSearchDate("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset w-100"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              <th>
                                <input
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                  className="me-1"
                                ></input>
                                Select
                              </th>
                              {visible.col1 && <th className="sr">Sr. No.</th>}
                              {visible.col2 && (
                                <th className="tax-name">Invoice No.</th>
                              )}
                              {visible.col3 && (
                                <th className="tax-name">Customer Name</th>
                              )}{" "}
                              {visible.col19 && (
                                <th className="tax-name">
                                  Customer Mobile Number
                                </th>
                              )}
                              {visible.col6 && (
                                <th className="tax-name">Product Name</th>
                              )}{" "}
                              {visible.col14 && user?.id === 1 && (
                                <th className="tax-name">Seller Name</th>
                              )}
                              {visible.col10 && (
                                <th className="tax-name">Payment Status</th>
                              )}{" "}
                              {visible.col7 && (
                                <th className="tax-name">Amount</th>
                              )}
                              {visible.col9 && (
                                <th className="tax-name">Payment Mode</th>
                              )}{" "}
                              {visible.col8 && (
                                <th className="tax-name">Order Status</th>
                              )}
                              {visible.col4 && (
                                <th className="tax-name">Order Date</th>
                              )}
                              {visible.col5 && (
                                <th className="tax-name">Delivery Date</th>
                              )}{" "}
                              {visible.col11 && (
                                <th className="tax-name">Delivery Pincode</th>
                              )}
                              {visible.col13 && (
                                <th className="tax-name">Action</th>
                              )}
                              {visible.col14 && (
                                <th className="tax-name">Open Delivery</th>
                              )}{" "}
                              {visible.col17 && (
                                <th className="tax-name">
                                  Delivery Instruction
                                </th>
                              )}
                              {visible.col16 && (
                                <th className="tax-name">Delivery Boy</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {isAllow.includes(5) ? ( */}
                            {data?.data?.data?.map((d, index) => {
                              return (
                                <tr className="" key={index}>
                                  <td>
                                    {/* {(d.order_status_id === 1 ||
                                    d.order_status_id === 2 ||
                                    d.order_status_id === 3) && ( */}
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    />
                                    {/* )} */}
                                  </td>
                                  {visible.col1 && <td>{++index}.</td>}
                                  {visible.col2 && <td>{d?.invoice_number}</td>}
                                  {visible.col3 && (
                                    <td>{d?.user?.first_name}</td>
                                  )}
                                  {visible.col19 && (
                                    <td>{d?.user?.contact_no}</td>
                                  )}{" "}
                                  {visible.col6 && (
                                    <td>
                                      {d?.orders_details?.map(
                                        (orderDetail, index) => (
                                          <div key={index}>
                                            {orderDetail?.product?.name}
                                          </div>
                                        )
                                      )}
                                    </td>
                                  )}{" "}
                                  {visible.col14 && user.id === 1 && (
                                    <td>
                                      {d?.orders_details?.map(
                                        (orderDetail, index) => (
                                          <div key={index}>
                                            {
                                              orderDetail?.product?.user
                                                ?.first_name
                                            }
                                          </div>
                                        )
                                      )}
                                    </td>
                                  )}
                                  {visible.col10 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          disabled={user.id === 1}
                                          style={{
                                            backgroundColor: d.payment_status
                                              .status
                                              ? "green"
                                              : "red",
                                          }}
                                          checked={d.payment_status.status}
                                          // onChange={() => {
                                          //   ChangeStatus(d.id);
                                          // }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.payment_status.status
                                            ? "Paid"
                                            : "Unpaid"}
                                        </label>
                                      </div>
                                    </td>
                                  )}{" "}
                                  {visible.col7 && <td>{d?.amount}</td>}
                                  {visible.col9 && (
                                    <td>{d?.payment_mode}</td>
                                  )}{" "}
                                  {visible.col8 && (
                                    <td>{d?.order_status?.order_status}</td>
                                  )}
                                  {visible.col4 && (
                                    <td>{d?.createdAt.split("T")[0]}</td>
                                  )}
                                  {visible.col5 && (
                                    <td>
                                      {
                                        d?.delivery_date
                                          ?.replace("T", " ")
                                          .split(".")[0]
                                      }
                                    </td>
                                  )}
                                  {visible.col11 && (
                                    <td>{d?.users_address?.pincode?.name}</td>
                                  )}
                                  {visible.col14 && (
                                    <td>
                                      {d?.open_delivery == 1 ? "Yes" : "No"}
                                    </td>
                                  )}{" "}
                                  {visible.col17 && (
                                    <td>{d?.delivery_instruction}</td>
                                  )}
                                  {visible.col16 && (
                                    <td>{d?.delivery_boy?.first_name}</td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
