import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Select from "react-select";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, SelectUsers } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const imageFile = watch("image");
  const password = watch("password", "");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("transaction_id", data?.transaction_id);
      sendData.append("seller_id", data?.seller_id?.value);
      sendData.append("plan", data?.plan);
      const response = await postData(
        `/transaction-data/subscription-history/${id}`,
        sendData
      );

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [seller, setSeller] = useState([]);
  console.log(seller, "seller");

  const GetAllSeller = async () => {
    const response = await getData("/all-sellers");

    if (response?.success) {
      setSeller(await SelectUsers(response?.data, "seller_id"));
    }
  };

  useEffect(() => {
    GetAllSeller();
  }, []);

  const GetEditData = async () => {
    const response = await getData(
      `/transaction-data/subscription-history/${id}`
    );
    reset({
      ...response?.data,
      password: "",
      confirmPassword: "",
    });
  };

  useEffect(() => {
    GetEditData();
  }, []);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Subscription History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Transaction Id</Form.Label>

                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="transaction_id"
                            placeholder="Transaction Id"
                            className={classNames("", {
                              "is-invalid": errors?.transaction_id,
                              // "is-valid": getValues("first_name"),
                            })}
                            {...register("transaction_id", {
                              required: "Transaction Id is required",
                              // pattern: {
                              //   value: /^[A-Za-z]+$/,
                              //   message: "Invalid Format",
                              // },
                            })}
                          />
                        </InputGroup>
                        {errors.transaction_id && (
                          <span className="text-danger">
                            {errors.transaction_id.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Label>Seller</Form.Label>
                      <Controller
                        name="seller_id" // name of the field
                        {...register("seller_id", {
                          required: "Select Seller",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.seller_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={seller}
                          />
                        )}
                      />
                      {errors.seller_id && (
                        <span className="text-danger">
                          {errors.seller_id.message}
                        </span>
                      )}{" "}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Plan</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            name="plan"
                            placeholder="Plan"
                            className={classNames("", {
                              "is-invalid": errors?.plan,
                            })}
                            {...register("plan", {
                              required: "Plan is required",
                            })}
                          />
                        </InputGroup>
                        {errors.plan && (
                          <span className="text-danger">
                            {errors.plan.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Col>

              {/* <Col lg={6}> */}

              {/* </Col> */}

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
